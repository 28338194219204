<template>
    <td :style="{ '--color': color, '--time': time }">
        <transition name="slide-fade" mode="out-in">
            <span :key="data" :class="bold ? 'bold text' : 'text'">
                {{ data }}
            </span>
        </transition>
    </td>
</template>

<script>
    export default {
        props: {
            data: {
                type: [String, Number],
                default: 'Test',
            },
            color: {
                type: String,
                default: '#2196F3',
            },
            time: {
                type: String,
                default: '10s ease',
            },
            bold: {
                type: Boolean,
                default: false,
            },
        },
        mounted() {},
    }
</script>

<style lang="stylus" scoped>
    .slide-fade-enter-active
        transition background-color var(--time)

    .slide-fade-leave-active
        transition background-color 0s ease

    .slide-fade-enter, .slide-fade-leave-to
        background-color var(--color)

    .text
        border-radius 3px
        padding 4px 4px
        left -4px
        position relative

    .bold
        font-weight 500
</style>
