export default {
    created: function() {
    },
    methods: {
        notifyToast(text, data) {
            if (!data) data = {}
            const { color, timeout, title } = data

            let type
            if (color === 'success') {
                type = 'success'
            } else if (color === 'error') {
                type = 'error'
            } else if (color === 'warning') {
                type = 'warn'
            // } else if (color === 'info') {
            }

            this.$notify({
                title,
                text,
                type,
                duration: timeout,
            })
        },
    },
}
