import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    extends: Pie,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: null,
        },
        options: {
            type: Object,
            default: null,
        },
    },
    mounted() {
        // this.chartData создаётся внутри миксина.
        // Если вы хотите передать опции, создайте локальный объект options
        this.renderChart(this.chartData, this.options)
    },
}
